import React from 'react'
import { companyName } from '../data/companyInfo.json'
import { panelColors } from '../data/themes.js'
import Panel from '../components/Panel.js'
import Statement from '../components/Statement.js'
import Recommendation from '../components/Recomendation.js'
import Gareth from '../recommenders/gareth.jpg'
import Ana from '../recommenders/ana.jpg'
import { CaseStudy, Thumbnail, Slide } from '../case-studies'


export default () => <div>
  <Panel theme={panelColors.allGreen} scrollIcon>
    <Statement title="technology">
      <p>We craft fast, secure, and international technology solutions that power cutting-edge creative experiences.</p>
    </Statement>
  </Panel>

  <Panel theme={panelColors.cake} scrollIcon>
    <Statement title="advancement">
      <p>Whether it's 3D in the browser, real-time collaboration with WebRTC, or progressive web apps we help teams understand new capabilities and turn them into creative opportunities.</p>
    </Statement>
  </Panel>

  <CaseStudy slides={[(
    <Slide mobileRow early>
      <Thumbnail
        width="281"
        height="500"
        mobileSmall
        path="/intel-3d-homepage/homepage-mobile"
        alt="Intel 3D Homepage Mobile" />
      <aside>
        <h2>Intel's 7<sup>th</sup> Gen Processor Launch</h2>
        <time>June 2017</time>
        <p>The debut of Intel's 7<sup>th</sup> gen processors was a huge milestone for the company. In recognition of the event we created a custom responsive homepage with unique 3D elements.</p>
        <p>The advancing field of stars represents Intel's continuous technilogical advancement.</p>
      </aside>
    </Slide>
  ), (
    <Slide vertical>
      <Thumbnail
        width="640"
        height="400"
        path="/intel-3d-homepage/homepage-desktop"
        alt="Intel 3D Homepage Desktop" />
      <aside>
        <p><a target="_blank" rel="noopener noreferrer" href="https://velomash.github.io/intel-aidx-prototypes/single.html">See the historic 7th Gen Intel Launch page</a></p>
      </aside>
    </Slide>
  )]} />

  <Panel theme={panelColors.pinkCandy} scrollIcon>
    <Statement title="architecture">
      <p>We construct cost-effective cloud strategies that run media encoding, highly available systems, resilient backends, and much more.</p>
    </Statement>
  </Panel>

  <Panel theme={panelColors.italy} scrollIcon>
    <Statement title="collaboration">
      <p>Our design work, love of art, and passion for user experience naturally align us with creative and ux teams. We forge cross-functional partnerships that strengthen usability, interaction design, and the overall product.</p>
    </Statement>
  </Panel>

  <Recommendation
    author="Gareth Botha"
    picture={Gareth}
    quote="Adam truly is a web designer's best friend. Handing a design off to him for development is always a stress-free experience, as his exceptional attention to detail means that projects come back pixel-perfect every single time. Adam also stays up to date on industry standards and is always ready with innovative, problem-solving solutions and ideas."
  />

  <Panel theme={panelColors.eggplant} scrollIcon>
    <Statement title="code craft">
      <p>Our quality digital craftsmanship assures investments in engineering today return maintainable, profitable, and engaging software that stands the test of time.</p>
    </Statement>
  </Panel>

  <CaseStudy slides={[(
    <Slide mobileRow early>
      <Thumbnail
        width="281"
        height="500"
        mobileSmall
        path="/tmobile-whytmo/why-tmo-mobile"
        alt="Why T-Mobile mobile page" />
      <aside>
        <h2>Converting wireless users to T-Mobile</h2>
        <time>Aug 2015</time>
        <p>As part of T-Mobile &amp; Publicis' wildly successful Un-carrier campaign we helped architect the Why T-Mobile page. This illustrated and animated page laid out how other wireless carriers mistreated their customers.</p>
      </aside>
    </Slide>
  ), (
    <Slide vertical>
      <Thumbnail
        width="800"
        height="500"
        path="/tmobile-whytmo/why-tmo-desktop"
        alt="Why T-Mobile desktop page" />
      <aside>
        <p><a target="_blank" rel="noopener noreferrer" href="https://velomash.github.io/why-tmobile">Take a look at an archived version</a>.</p>
      </aside>
    </Slide>
  )]} />

  <Panel theme={panelColors.gellato} scrollIcon>
    <Statement title="security">
      <p>Honoring and empathizing with your users means respecting their privacy and security. In 2018 that respect is mandatory. Spearheading engineering best practices is one aspect of the solution, but it has to go further. We'll help {companyName} spread a culture of security-awareness where everyone knows how to play a part in keeping our business and users safe.</p>
    </Statement>
  </Panel>

  <Recommendation
    author="Ana Gaisiner"
    picture={Ana}
    position="Sr. Functional Analyst"
    quote="Adam is one of the most knowledgeable and well-spoken developers I've had the privileged to work with. His attention to detail, pride in delivering clean code, and ability to present and communicate complex technical solutions to a wide range of stakeholders adds immeasurable value to any team he joins. He is calm and easy going person who brings thoughtfulness and humor to the team dynamic."
  />

  <Panel theme={panelColors.easter} scrollIcon>
    <Statement title="reliability">
      <p>There's no reason to ever get <em>that</em> text at 4am. Automated testing, continuous integration, and practical git workflows are a good start, but it's continuous communication which make devops successful.</p>
    </Statement>
  </Panel>

  <Panel theme={panelColors.allGreen}>
    <Statement>
      <p>
        Technology is the campfire<br/>
        around which we tell our stories.
      </p>
      <cite>Laurie Anderson</cite>
    </Statement>
  </Panel>
</div>

import React from 'react';
import Panel from '../components/Panel.js'
import Statement from '../components/Statement.js'
import FullscreenVideo from '../components/FullscreenVideo.js'
import { panelColors } from '../data/themes.js'
import ReactGA from 'react-ga'

function analyticsEvent(event) {
  ReactGA.event({
    category: 'Contact Us Page',
    action: event,
  })
}

export default () =>
  <Panel fullscreen theme={panelColors.intro}>
    <FullscreenVideo src="blurred-leaves.mp4"/>
    <Statement>
      <p>
        Let's talk about<br />
        working together.
      </p>
      <p>
        <a href="mailto:adam@goldengate.digital" onClick={() => analyticsEvent('Start Email')}>Send us an email</a>.
      </p>
    </Statement>
  </Panel>

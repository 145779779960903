import React from 'react'
import { Link } from 'react-router-dom'
import Panel from '../components/Panel.js'
import Statement from '../components/Statement.js'
import { panelColors } from '../data/themes.js'
import { ordinalNumber, getCurrentDay, getNumberOfVisits } from '../components/utilities.js'
import FullscreenVideo from '../components/FullscreenVideo.js'

const introLines = [
  <p>Happy {getCurrentDay()}! Thanks for visiting</p>,
  <p>Welcome back.</p>,
  <p>{ordinalNumber(getNumberOfVisits()+1)} visit? We're flattered.</p>,
  <p>Showing this page to a friend? If not you should be.</p>,
  <p>Hey, back again. Thank you.</p>,
  <p>{ordinalNumber(getNumberOfVisits()+1)} visit? We should have a chat.</p>,
];

export default () =>
  <Panel fullscreen theme={panelColors.intro}>
    <FullscreenVideo src="blurred-leaves.mp4"/>
    <Statement>
      {introLines[Math.min(getNumberOfVisits(), introLines.length-1)]}
      <p>
        We love <Link to="tech">clean code</Link><br/>
        and creating <Link to="ux">amazing experiences</Link>.
      </p>
      <p><Link to="contact">Let's chat!</Link></p>
    </Statement>
  </Panel>
